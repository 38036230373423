.static-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.tipps {
			background-image: url(RESOURCE/img/hero_ausflugsziele.webp);
		}

		&.faq {
			background-image: url(RESOURCE/img/hero_faq.webp);
		}

		&.kontakt {
			background-image: url(RESOURCE/img/hero_kontakt.webp);
		}

	}
}