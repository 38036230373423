body {
	&.dark {
		color: #fff;
		background-color: #333;

		.form-control {
			border: 1px solid #8d969e;

			&.formerror,
			.formerror:focus {
				border: 1px solid red;
			}
		}

		.heading1 {
			color: var(--color-white);

			&:before {
				background-color: var(--color-white);
			}
		}

		.searchpanel-v1 {
			border: none;
		}

		.searchpanel-v1 .sidebar-search {
			background-color: var(--dark-secondary-color);
		}

		.btn.btn-arrow .fa-sharp {
			opacity: 1;
		}

		.unit-title.h4 {
			color: var(--color-black);
		}

		.index-page-layout-v1 {

			.bg-grey-normal {
				background-color: #626161 !important;
			}

			.highlights .highlight {
				color: var(--color-white);

				.fa-sharp {
					color: var(--color-white);
				}
			}
		}

		.searchbar-v1 .search-form-area .form-flex .fa,
		.searchbar-v1 .search-form-area .form-flex .fa-sharp {
			color: var(--color-black);
		}

		.search-page-layout-v1 .share {
			color: var(--color-white);
		}

		.navbar-v1 .icon,
		.navbar-v1.affix .icon {
			color: var(--color-white);
		}

		.navbar-v1 .header-main .main-menu,
		.navbar-v1.affix .header-main .main-menu {
			@media(max-width:992px) {
				background-color: var(--color-black);
			}
		}

		.navbar-v1 .nav-row .col-12 .icons-nav li a,
		.navbar-v1.affix .nav-row .col-12 .icons-nav li a {
			@media(max-width:992px) {
				color: var(--color-white);
			}
		}

		.modal-title.h4 {
			color: var(--color-black);
		}

		.my-search-token {
			color: var(--color-black);
		}

		.popover {
			background-color: var(--dark-secondary-color);

			.popover-header {
				background-color: var(--dark-main-color);
			}

			a {
				color: var(--color-white);
			}

			.popover-body {
				ul {
					color: var(--color-white);

					li {
						color: var(--color-white);

						&:before {
							color: var(--color-white);
						}
					}
				}
			}

		}

		.popover-body ul li:before,
		body.dark .popover a .unit-card-v1,
		.facility-card,
		.search-unit-v1 .unit-item {
			background-color: var(--color-grey-light);
		}

		.unit-card-v1 .unit-container .price .rent-rate .price-details,
		.search-unit-v1 .unit-item .unit-container .price .rent-rate .price-details {
			border: 1px solid var(--color-black);
		}

		.search-unit-v1 .unit-item .unit-container .price .available {
			color: var(--color-black);
		}



		.asd__month-name {
			color: var(--color-black) !important;
		}

		.unit-sub-title-v1 .unit-subtitle .address,
		.unit-sub-title-v1 .unit-subtitle .type {
			color: var(--color-white);
		}

		.unit-page-layout-v1 .unitnav .container {
			background: var(--dark-main-color);
		}

		.unit-page-layout-v1 .unitnav .container div {
			background-color: var(--dark-secondary-color);
		}

		.unit-page-layout-v1 .unitnav .container div:hover {
			background-color: var(--dark-secondary-color);
			border: 1px solid var(--color-white);
		}

		.unit-features-v1 .feature-group {
			background-color: var(--dark-secondary-color);
		}

		.unit-page-layout-v1 .price-info {
			background-color: var(--dark-secondary-color);
		}

		.unit-service-v1 a {
			color: var(--color-white);
		}

		.unit-offers-v1 .unit-offer {
			background-color: var(--dark-secondary-color);
		}

		.unit-booking-v1 .travel-data {
			background-color: var(--dark-main-color);
		}

		.unit-booking-v1 .travel-data .travel-period-input {
			color: var(--color-white);
		}

		.unit-page-layout-v1 .voucher {
			background-color: var(--dark-secondary-color);
		}

		.unit-page-layout-v1 .showmore .content {
			background-color: var(--dark-main-color);
		}

		.unit-page-layout-v1 .showmore .header {
			background-color: var(--color-black);
			color: var(--color-white);
		}

		.unit-page-layout-v1 .showmore .title {
			color: var(--color-white);
		}

		.unit-page-layout-v1 .showmore .title .fa,
		.unit-page-layout-v1 .showmore .title .fa-light,
		.unit-page-layout-v1 .showmore .title .fa-sharp,
		.unit-page-layout-v1 .showmore .title .far,
		.unit-page-layout-v1 .showmore .title .fas {
			color: var(--color-white);
		}

		.unit-properties-v1 .d-title {
			background-color: var(--dark-secondary-color);
			color: var(--color-white);
		}

		.unit-properties-v1 .tick-li li:before {
			color: var(--color-white);
		}

		.unit-pricelist-v1 .pricelist-table .mainrow {
			color: var(--color-white);
			background-color: var(--dark-secondary-color);
		}

		.unit-calendar .legend-cal {
			color: var(--color-white);
		}

		.table.pricelist-table.table-hover {
			color: #fff;

			tr {
				color: #fff;

				&:hover {
					color: #fff;
					background-color: var(--color-black);
				}
			}
		}

		.table-hover>tbody>tr:hover>* {
			color: #fff;
		}

		.unit-calendar .calInfo {
			color: var(--color-white);
		}

		.btn-success {
			background-color: var(--color-black);
			color: var(--color-white);
			border: 1px solid var(--color-white);
		}

		.urv-plan {
			border: 1px solid var(--color-white);
		}

		.urv-detail-content {
			color: var(--color-white) !important;
		}

		.urv-detail-content a {
			color: var(--color-white);
		}

		.urv-logo {
			background-color: var(--color-white);
			padding: 5px;
		}
	}

	.modal-body {
		color: var(--color-black);
	}

	.contact-form-v1 .red {
		color: var(--color-white);
	}

	.contact-sidebar-v1 .contact-bl a {
		color: var(--color-primary);
	}

	.privacy-link {
		color: var(--color-primary);
	}

	.facility-card {
		color: var(--color-black);
	}

	.ferienanlagen-v1 .facility-card .facility-container .facility-facts .facility-title {
		color: var(--color-black);
	}

	.facility-page-layout-v1 .headline {
		color: var(--color-white);
	}
}

body {
	&.largefont {
		.small {
			font-size: 1.0rem !important;
		}

		.form-control {
			font-size: 16px !important;
		}

		.unit-card-v1 .unit-container .price .rent-rate .price-details {
			font-size: 16px !important;
		}

		.search-unit-v1 .unit-item .unit-container .unit-facts .unit-highlights .fa-stack,
		.unit-card-v1 .unit-container .unit-facts .unit-highlights .fa-stack {
			height: 40px !important;
		}

		/*.index-page-layout-v1 .highlights .highlight .text {
			font-size: 24px !important;
		}*/


	}
}